<template>
    <v-dialog
        :max-width="'500px !important'"
        persistent
        v-model="revertDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
    >
        <v-card>
            <v-card-title class="warning white--text justify-space-between">
                <span>{{ $t('message.revertLhdnInvoice') + ': ' + invoiceNumber }}</span>
            </v-card-title>
            <v-card-text>
                <v-form lazy-validation ref="revertForm" v-model="validForm">
                    <v-layout row pt-3>
                        <p class="black--text pl-5 mt-3 pb-0">A credit note is required to revert a previously issued e-invoice which is already more than 72 hours old since the date and time it was validated.</p>
                        <v-flex xs5 lg4 pl-5 py-1 class="align-center">{{ $t('message.referenceInvoiceNumber') }}</v-flex>
                        <v-flex xs7 lg8 py-1 pl-3 class="black--text text-darken-1">{{ invoiceNumber }}</v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg4 pl-5 py-1 class="align-center">{{ $t('message.creditNoteNumber') }}</v-flex>
                        <v-flex xs7 lg8 py-1>
                            <v-text-field
                                :loading="loading.creditNoteNumber"
                                :value="credit_note_number"
                                :label="credit_note_number ? '' : $t('message.required')"
                                :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                autocomplete="new-password"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                required
                                @change="credit_note_number = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg4 pl-5 py-1 class="align-center">{{ $t('message.revertReason') }}</v-flex>
                        <v-flex xs7 lg8 py-1>
                            <v-lazy>
                                <v-combobox
                                    :items="reasonOptions"
                                    :loading="loading.reasonOptions"
                                    :placeholder="$t('message.selectOptionOrFreeText')"
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumbers]"
                                    :value="revert_reason"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="revert_reason = $event"
                                ></v-combobox>
                            </v-lazy>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="revertDialog = false" >{{ $t('message.dismiss') }}</v-btn>
                <v-btn
                    :loading="loading.proceed"
                    color="info"
                    small
                    @click="revertDocument()"
                >{{ $t('message.proceed') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {api} from "Api";

export default {
    name: "RevertDocument",
    props: ['dialog', 'invoiceNumber','jobId','documentUuid'],
    data() {
        return {
            credit_note_number: null,
            loading: {
                creditNoteNumber: false,
                proceed: false,
                reasonOptions: false
            },
            reasonOptions: [],
            revert_dialog: false,
            revert_reason: null,
            validForm: true,
        }
    },
    computed: {
        ...mapGetters([
            'validationRules'
        ]),
        revertDialog: {
            get() {
                return this.revert_dialog;
            },
            set(value){
                this.revert_dialog = value
                if(value === false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        getNextCreditNoteNumber() {
            this.loading.creditNoteNumber = true
            api
                .get('/lhdn/next-document-no/cn')
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.credit_note_number = response.data.data
                    } else {
                        this.$toast.error(this.$t('message.errors.cannotGetNextCreditNoteNumber'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                    this.loading.creditNoteNumber = false
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.cannotGetNextCreditNoteNumber'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.creditNoteNumber = false
                })
        },
        async revertDocument() {
            if(this.$refs.revertForm.validate()) {
                if (await this.$root.$confirm(this.$t('message.revertLhdnInvoice') + ' ' + this.invoiceNumber, this.$t('message.confirmations.continueLhdnRevertAction'), {color: 'orange'})) {
                    this.loading.proceed = true;
                    api
                        .put('/lhdn/document/revert/' + this.jobId, {
                            credit_note_number: this.credit_note_number,
                            reason: this.revert_reason
                        })
                        .then((response) => {
                            if (response.data.status === 'success') {
                                this.$toast.success(this.$t('message.successes.lhdnReverted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loading.proceed = false;
                                this.$emit('revert-done')
                                this.revertDialog = false;
                            } else {
                                this.$toast.error(this.$t('message.errors.lhdnNotReverted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.proceed = false;
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.lhdnNotReverted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.proceed = false;
                        })
                }
            } else {
                this.$toast.error(this.$t('message.errors.lhdnNotReverted'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        getReasonOptions() {
            this.loading.reasonOptions = true
            api
                .get('/lhdn/reason-options')
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.reasonOptions = response.data.data
                    } else {
                        console.log('Reason options cannot be loaded')
                    }
                    this.loading.reasonOptions = false
                })
                .catch(() => {
                    console.log('Reason options cannot be loaded')
                    this.loading.reasonOptions = false
                })
        }
    },
    watch: {
        dialog(value) {
            if(value) {
                this.credit_note_number = null;
                this.revert_reason = null;
                this.getNextCreditNoteNumber();
                this.getReasonOptions();
            }
            this.revert_dialog = value;
        }
    },
}
</script>

<style scoped>

</style>